.header {height:10em; background:url(../../../public/images/pattern_bg.png),linear-gradient(#0f0f0f,#515151); text-align: center;}
.header .logo {display: inline-block;width: 272px;height: 110px;background:url(../../../public/images/charcoal_web@2x.png) no-repeat; background-size: 272px 110px;margin-top:30px;}
.status-box{height: 6.875rem;color:#fff;font-family: robotomedium;}
.status-box.active {background:#00d340;}
.status-box.cancelled {background:#ff0000;}
.status-box.completed {background:#9b9b9b;}
.status-box.withdrawn {background:#ff0000;}
.status-box.nofound {background:#ff0000;}
.status-box.declined {background:#ff0000;}
.status-box.annual-renew {background:#9b9b9b; height: 7.5rem;}
.status-box .small{font-size: 18px;line-height: 24px;padding-top: 15px;}
.status-box .large{font-size: 40px;line-height: 54px;}
.status-box .about-link {color: #12ff88;}
.validation-list {margin: 0;}
.validation-list dt{color: #9b9b9b;font-size: 18px;line-height: 24px;padding-top:25px;}
.validation-list dd{margin-left: 0;font-size: 28px;line-height: 37px;padding: 12px 0; border-bottom: 1px solid #e0e0e0;}
.validation-list .data-icon{display: inline-block;width: 46px;height: 46px;background: url(../../../public/images/Calendar_black_web@2x.png) no-repeat;background-size: 46px;vertical-align: middle;margin-right: 4px;}
.validation-box p {font-family: robotomedium;font-size: 14px;line-height: 24px;color: #4a4a4a;}
.about-box {background: #f5f5f5; font-size: 14px; line-height: 19px; padding: 20px 0 40px; color: #323232;}
.about-box p {margin-top: 0;margin-bottom: 2rem;}
.about-box .about-link {color: #027dfe;}
.about-box .about-link:hover {text-decoration: none;}
@media screen and (min-width: 0rem) and (max-width:39.9375rem) {
  .header {height:5.5rem;background:url(../../../public/images/pattern_mobile.png),linear-gradient(#0f0f0f,#454545);}
  .status-box{height: 4.5rem;}
  .status-box.annual-renew {height: auto;}
  .header .logo{width: 132px;height: 53px;background:url(../../../public/images/charcoal_mobile@2x.png) no-repeat; background-size: 132px 53px;margin-top:20px;}
  .box-inner{margin:0 24px; }
  .status-box .small{font-size: 14px;line-height: 19px;padding: 10px 0 1px;}
  .status-box .large{font-size: 24px;line-height: 32px;}
  .validation-list dt{font-size: 14px;line-height: 19px;padding-top:10px;}
  .validation-list dd{font-size: 18px;line-height: 24px;padding: 9px 0;}
  .validation-list dd.has-icon{padding: 9px 0 5px 0;}
  .validation-list .data-icon{display: inline-block;width: 30px;height: 30px;background: url(../../../public/images/Calendar_black_mobile@2x.png) no-repeat;background-size: 30px;margin:0;}
}
@media screen and (min-width: 40rem) and (max-width:63.9375rem) {
  .box-inner{margin:0 82px; }
  .validation-list{padding-bottom: 4px;}
  .validation-box p {font-size: 18px;}
  .about-box {padding: 60px 0 80px;}
}
@media screen and (min-width: 64rem) {
  .box-inner{width:780px; margin:0 auto; }
  .validation-list{padding-bottom: 4px;}
}

/* Thimble Reset */
.smb-header {
  height: 5rem;
  text-align: center;
}
.smb-header .logo-box {
  padding-top: 1.5rem;
}
.smb-header .logo-spin {
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  vertical-align: middle;
}
.smb-header .logo-text {
  display: inline-block;
  height: 1.625rem;
  vertical-align: text-bottom;
}
.logo-spin img {
  width: 100%;
}
.logo-text img {
  height: 100%;
}
.smb-wrap .status-box {
  font-family: CentraThimble;
  font-weight: 500;
}
.smb-wrap .status-box .small {
  font-weight: 300;
}
.smb-wrap .validation-list,
.smb-wrap .about-box {
  font-family: CentraThimble;
  font-weight: 300;
}
.smb-wrap .validation-box p {
  font-family: CentraThimble;
  font-weight: 500;
}
.smb-wrap .status-box.active {background:#07DB7F;}
.smb-wrap .status-box.cancelled {background:#FC534E;}
.smb-wrap .status-box.completed {background:#9b9b9b;}
.smb-wrap .status-box.withdrawn {background:#FC534E;}
.smb-wrap .status-box.nofound {background:#FC534E;}
.smb-wrap .status-box.declined {background:#FC534E;}
