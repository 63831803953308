@font-face {
    font-family: 'robotoregular';
    src: url('../../../public/fonts/Roboto-Regular-webfont.eot');
    src: url('../../../public/fonts/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../public/fonts/Roboto-Regular-webfont.woff') format('woff'),
         url('../../../public/fonts/Roboto-Regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotomedium';
    src: url('../../../public/fonts/Roboto-Medium-webfont.eot');
    src: url('../../../public/fonts/Roboto-Medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../public/fonts/Roboto-Medium-webfont.woff') format('woff'),
         url('../../../public/fonts/Roboto-Medium-webfont.svg#robotomedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'CentraThimble';
    font-style: normal;
    font-weight: 300;
    src: local('Centra Thimble Book'),
         local('CentraThimble-Book'),
         url('../../../public/fonts/CentraThimble-Book.woff') format('woff'),
         url('../../../public/fonts/CentraThimble-Book.woff2') format('woff2');
}
@font-face {
    font-family: 'CentraThimble';
    font-style: normal;
    font-weight: 500;
    src: local('Centra Thimble Medium'),
         local('CentraThimble-Medium'),
         url('../../../public/fonts/CentraThimble-Medium.woff') format('woff'),
         url('../../../public/fonts/CentraThimble-Medium.woff2') format('woff2');
}
